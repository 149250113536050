<template>
  <div class="page-container help">
    <div class="page-header">
      <ace-header show-back>
        {{ $t('system.app.helpCenter') }}
      </ace-header>
    </div>
    <div class="page-body pt-10px px-10px">
      <div class="mb-10px p-20px bg-secondary rounded-6px">
        <div class="mb-20px text-large">{{ $t('system.help.helpTipsTitle') }}</div>
        <ul class="pl-20px list-decimal">
          <li class="mb-20px">{{ $t('system.help.helpTips_1') }}</li>
          <li>{{ $t('system.help.helpTips_2') }}</li>
        </ul>
      </div>
      <div class="mb-10px p-20px bg-secondary rounded-6px">
        <div class="mb-20px text-large">{{ $t('system.help.serviceTitle') }}</div>
        <ul class="pl-20px list-decimal">
          <li class="mb-20px">{{ $t('system.help.service_1') }}</li>
          <li class="mb-20px">{{ $t('system.help.service_2') }}</li>
          <li>{{ $t('system.help.service_3') }}</li>
        </ul>
      </div>
      <div class="mb-10px p-20px bg-secondary rounded-6px">
        <div class="mb-20px text-large">{{ $t('system.help.contactTitle') }}</div>
        <ul class="pl-20px list-decimal">
          <li class="mb-20px">
            {{ $t('system.help.contactEmail') }}:
            <a href="mailto:cs@aceplaying.com">cs@aceplaying.com</a>
          </li>
          <li class="mb-20px">
            {{ $t('system.help.contactFacebook') }}:
            <a
              href="https://www.facebook.com/DMENDEFENDERS"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/DMENDEFENDERS
            </a>
          </li>
          <li>
            {{ $t('system.help.contactDiscord') }}:
            <a href="https://discord.gg/DETkJFSFb5" target="_blank" rel="noopener noreferrer">
              https://discord.gg/DETkJFSFb5
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Help',
  };
</script>
